import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';

interface UseWindowResize {
    (cb: () => void, timeout?: number): void;
}

const useWindowResize: UseWindowResize = (cb: () => void, timeout = 300) => {
    const resizeListener = useMemo(() => debounce(cb, timeout), [cb, timeout]);
    useEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [resizeListener]);
};

export default useWindowResize;
