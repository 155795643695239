import styled from '@emotion/styled';

interface YellowBackgroundBurstProps {
    children: React.ReactNode;
    className?: string;
}

const YellowBackgroundBurst = styled.span<YellowBackgroundBurstProps>`
    background: radial-gradient(65% 65% at 50% 50%, #ceeb38bf 0%, transparent 100%);
`;

export default YellowBackgroundBurst;
