import { forwardRef, Ref } from 'react';

import cn from '@/lib/cn';

interface SectionWrapperProps {
    id?: string;
    className?: string;
    children: React.ReactNode;
}

const SectionWrapper = forwardRef(function SectionWrapper(
    { className, children, id }: SectionWrapperProps,
    ref: Ref<HTMLDivElement>
) {
    return (
        <div
            ref={ref}
            id={id}
            className={cn('pt-28 px-8 pb-5 md:px-16 md:pt-28', className)}
        >
            {children}
        </div>
    );
});

export default SectionWrapper;
