import cn from '@/lib/cn';

interface FloatingTagPillProps {
    children: React.ReactNode;
    className?: string;
}

const FloatingTagPill = ({ className, children }: FloatingTagPillProps) => (
    <div
        className={cn(
            'text-center leading-[1.75] bg-white rounded-full border border-analyst-dark-lavender px-4 py-3 md:px-5 md:py-4 text-analyst-black text-base md:text-xl shadow-md font-brand-md',
            className
        )}
    >
        {children}
    </div>
);

export default FloatingTagPill;
