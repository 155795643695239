import { CompanySearchField } from '@/components/company/company-search-field';
import { Heading } from '@/components/dom/text-elements';
import SectionWrapper from '@/components/global/SectionWrapper';
import YellowBackgroundBurst from '@/components/ui/YellowBackgroundBurst';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';

interface TryItYourselfSectionProps {
    className?: string;
}

const TryItYourselfSection = ({ className }: TryItYourselfSectionProps) => {
    const { locations } = usePosthogTracking();

    return (
        <SectionWrapper className={cn('pt-20 md:pt-28 mb-10', className)}>
            <div className="bg-white rounded-lg shadow-md p-4 md:p-8 border border-analyst-dark-lavender flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row items-center justify-between">
                <div className="flex flex-col gap-3">
                    <Heading
                        importance={3}
                        className="text-4xl lg:text-[48px] leading-[1.2] font-alt-riveria-bold"
                    >
                        Jumpstart your <YellowBackgroundBurst>research</YellowBackgroundBurst>.
                    </Heading>
                </div>

                <CompanySearchField
                    className="w-full max-w-[500px]"
                    trackingProperties={{
                        section: locations.TRY_IT_YOURSELF_SECTION,
                    }}
                />
            </div>
        </SectionWrapper>
    );
};

export default TryItYourselfSection;
