import cn from '@/lib/cn';
import theme from '@/lib/tailwindConfig';

type ColorType = keyof typeof theme.colors;

type ColorMapType = {
    [key in ColorType]?: string;
};

const colorMap: ColorMapType = {
    'analyst-lavender': 'bg-radient-circle-c from-analyst-lavender from-0% to-50%',
    'analyst-lavender-medium': 'bg-radient-circle-c from-analyst-lavender-medium from-0% to-50%',
    'thematic-light-blue': 'bg-radient-circle-c from-thematic-light-blue from-0% to-50%',
    'thematic-light-yellow': 'bg-radient-circle-c from-thematic-light-yellow from-0% to-50%',
    'thematic-neon-yellow': 'bg-radient-circle-c from-thematic-neon-yellow from-0% to-50%',
    'thematic-pink': 'bg-radient-circle-c from-thematic-pink from-0% to-50%',
    'thematic-purple': 'bg-radient-circle-c from-thematic-purple from-0% to-50%',
};

interface CircleBurstProps {
    colorType: ColorType;
    className?: string;
    children?: React.ReactNode;
}

const CircleBurst = ({ colorType, children, className }: CircleBurstProps) => {
    return <div className={cn(colorMap[colorType], className)}>{children}</div>;
};
export default CircleBurst;
