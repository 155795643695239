'use client';

import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import cn from '@/lib/cn';

// Main
interface ProgressBarProps {
    autoReset?: boolean;
    autoMode?: boolean;
    defaultProgress?: number;
    className?: string;
    mainBarClassName?: string;
    progressBarClassName?: string;
    timing?: number;
    resetProgressBar?: boolean;
    startProgressBar?: boolean;
    smoothProgression?: boolean;
}

export default function ProgressBar({
    autoReset = false,
    autoMode = false,
    defaultProgress = 0,
    className,
    mainBarClassName,
    progressBarClassName,
    resetProgressBar = false,
    startProgressBar = false,
    smoothProgression = false,
    timing = 500,
}: ProgressBarProps) {
    const [progress, setProgress] = useState(defaultProgress);
    const [startProgression, setStartProgression] = useState(false);

    const previousResetProgressPropState = usePrevious(resetProgressBar);
    const previousStartProgressPropState = usePrevious(startProgressBar);

    useEffect(() => {
        const timer =
            !smoothProgression &&
            autoMode &&
            setInterval(() => {
                setProgress((oldProgress) => {
                    if (autoReset && oldProgress === 100) {
                        return 0;
                    }

                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, timing);

        return () => {
            timer && clearInterval(timer);
        };
    }, [autoReset, autoMode, smoothProgression, timing]);

    useEffect(() => {
        if (smoothProgression && !previousStartProgressPropState && startProgressBar) {
            setStartProgression(true);
        }
    }, [previousStartProgressPropState, smoothProgression, startProgressBar]);

    useEffect(() => {
        if (smoothProgression && !previousResetProgressPropState && resetProgressBar) {
            setStartProgression(false);
        }
    }, [previousResetProgressPropState, smoothProgression, resetProgressBar]);

    return (
        <div className={cn('w-full', className)}>
            <div className={cn('bg-brand-gray-med h-2 block relative overflow-hidden rounded-full', mainBarClassName)}>
                <div
                    className={cn(
                        'bg-brand-blue h-full w-full block absolute top-0 left-0 overflow-hidden z-10 transition-transform linear -translate-x-full',
                        {
                            'transition-transform ease-in-out translate-x-0': smoothProgression && startProgression,
                        },
                        progressBarClassName
                    )}
                    style={{
                        transform: !smoothProgression ? `translateX(${progress - 100}%)` : '',
                    }}
                ></div>
            </div>
        </div>
    );
}
