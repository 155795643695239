import { MouseEvent, TouchEventHandler, useEffect, useState } from 'react';

import { Nullable } from '@/types/nullable';

import scrollIntoViewWithOffset, { type ScrollIntoViewWithOffsetArgs } from '../scrollIntoViewWithOffset';

const useSmoothScrollTo = ({
    rootElem,
    scrollToElement,
    offsetPosition = 0,
    callback,
    positionElementInMiddle = false,
    scrollToTop = false,
    scrollToEnd = false,
}: ScrollIntoViewWithOffsetArgs) => {
    const [windowElement, setWindowElement] = useState<Nullable<Window>>(null);
    const halfViewportHeight = windowElement?.innerHeight || 0 / 2;
    const { height = 0 } = scrollToElement?.getBoundingClientRect() || {};
    const scrollToElementHalfwayPoint = halfViewportHeight - height / 2;
    const offset = positionElementInMiddle ? scrollToElementHalfwayPoint : offsetPosition;
    const onClick = (evt?: MouseEvent<HTMLButtonElement>) => {
        evt?.preventDefault();
        if (scrollToElement && !scrollToTop) {
            scrollIntoViewWithOffset({
                callback,
                offsetPosition: offset,
                rootElem,
                scrollToElement,
                scrollToEnd,
            });
        } else if (scrollToTop) {
            windowElement?.scrollTo({ behavior: 'smooth', top: 0 });
        }
    };

    const onTouchEnd: TouchEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        if (scrollToElement) {
            scrollIntoViewWithOffset({ callback, offsetPosition: offset, rootElem, scrollToElement });
        }
    };

    useEffect(() => {
        setWindowElement(window);
    }, []);

    return { onClick, onTouchEnd };
};

export default useSmoothScrollTo;
