import CircleCheckmark from '@/assets/icons/circle-checkmark.svg';
import cn from '@/lib/cn';

interface CheckmarkPillProps {
    children: React.ReactNode;
    className?: string;
}

const CheckmarkPill = ({ className, children }: CheckmarkPillProps) => (
    <span
        className={cn(
            'text-left border border-analyst-dark-lavender rounded-full px-3 py-1.5 text-sm md:text-lg text-analyst-black flex items-center justify-start gap-2 shadow-md bg-primary-bg-linear-gradient-vertical',
            className
        )}
    >
        <CircleCheckmark className="w-6 text-analyst-blue flex-shrink-0" />
        {children}
    </span>
);

export default CheckmarkPill;
