import cn from '@/lib/cn';

interface ThematicPillProps {
    className?: string;
    children?: React.ReactNode;
}

const ThematicPill = ({ className, children = 'Thematic' }: ThematicPillProps) => (
    <span className={cn('bg-analyst-black rounded-full px-4 py-1.5 text-white font-brand-md', className)}>
        {children}
    </span>
);

export default ThematicPill;
